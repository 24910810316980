import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { Button, Col, Container, Row, Badge } from 'react-bootstrap'

import Pagination from 'react-js-pagination'

import api from '@/api'
import { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useStatusTranslation } from '@/common/hooks'



const AdminsJobsList = () => {
  const navigation = useNavigate()
  const { search } = useLocation()
  const [resultCount, setResultCount] = useState(0)
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])
  const [searchCount, setSearchCount] = useState(0)
  const [showDeleteCode, setShowDeleteCode] = useState(false)
  const [deleteModalProp, setDeleteModalProp] = useState({ code: {} } as any)

  const statusToKorean = (status: string) => {
    switch (status) {
      case 'done':
        return '작업완료'
      case 'destroyed':
        return '시간초과'
      case 'created':
        return '결제대기'
      case 'purchased':
        return '결제완료'
      default: return status;
    }
  }



  const fetch = (params: any) => {
    api.admins.jobs.listJobsToday({
      page: params.page,
      query: params.query,
    }).then((res: any) => {
      setResults(res.result.jobs)
      // setSearchCount(res.result.total)
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    return window.location.href = (`/admins/jobs/list-today?page=${page}&query=${query}`)
  }

  useEffect(() => {
    const values = queryString.parse(search)
    const queryPage = values.page ? Number(values.page) : 1
    const queryQuery = values.query ? String(values.query) : ''
    console.info(`useEffect[page]: ${queryPage}`)
    console.info(`useEffect[query]: ${queryQuery}`)
    setPage(prevPage => queryPage)
    setQuery(prevQuery => queryQuery)
    api.admins.jobs.countAllJobsToday().then((res: any) => {
      setResultCount(res.result.count)
      fetch({ page: queryPage, query: queryQuery })
    }).catch((e) => {
      console.error(e)
      alert("서버오류가 발생하였습니다")
    })
  }, [search])


  return (
    <>
      <Container fluid>
        <Row className="mt-2">
          <p className="text-secondary">
            작업목록
          </p>
        </Row>
        <Row className="bg-white mb-4">
          <Row className="mt-2 mb-2">
            <Col>
              전체 작업: <b>{resultCount.toLocaleString()}</b>건
            </Col>
          </Row>
          {/* <Row className="mb-2">
            <Row className="mb-2">
              <Col sm={12} lg={8}>
                <Form.Control
                  type="text"
                  placeholder="검색어를 입력하세요(이름/코드)"
                  size="sm"
                  value={query}
                  onChange={(e) => { setQuery(e.target.value) }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  size="sm"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    return window.location.href = (`/admins/branches/codes/list?page=1&query=${query}`)
                  }}
                >검색</Button>
              </Col>
            </Row>
          </Row> */}
        </Row>
        <Row className="bg-white pt-2 pb-2 ">
          <Col>
          </Col>
          <Col
            className="d-flex justify-content-end"

          >
            {/* <Button
              size="sm"
              className="align-self-end"
              onClick={() => {
                return window.location.href = ('/admins/branches/codes/create')
              }}
            >
              신규코드 생성
            </Button> */}

          </Col>
        </Row>
        <Row className="bg-white">
          <Table bordered hover responsive="lg" className="table-sm">
            <thead>
              <tr>
                <th>가맹점명</th>
                <th>코드</th>
                <th>데스크탑버전</th>
                <th>Rx</th>
                <th>Tx</th>
                <th>Ping</th>
                <th>문서제목</th>
                <th>페이지</th>
                <th>색상</th>
                <th>가격</th>
                <th>상태</th>
                <th>결제구분</th>
                <th>생성일시</th>
              </tr>
            </thead>
            <tbody>
              {
                results.map((job: any) => {
                  return (
                    <tr key={job._id}>
                      <td>
                        {job.agencyName || ""}
                      </td>
                      <td>
                        {job.code || ""} <br />
                        {job.codeName || ""}
                      </td>
                      <td>
                        {job.version || ""}
                      </td>
                      <td>
                        {job.bytesReceived || ""}
                      </td>
                      <td>
                        {job.bytesWritten || ""}
                      </td>
                      <td>
                        {job.ping ? "true" : "false"}
                      </td>
                      <td title={job.name || ""} style={{ maxWidth: "10vw", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{job.name || ""}</td>
                      <td>{job.pages || ""}</td>
                      <td>{job.color ? "컬러" : "흑백"}</td>
                      <td>{job.amount || ""}</td>
                      <td>{statusToKorean(job.status || "")}</td>
                      <td>
                        {
                          job?.customerId ? "모바일" :
                            (job?.status === "done" || job?.status === "purchased") ? "카드" : "-"
                        }
                      </td>
                      <td>
                        {dayjs(job.createdAt).format('YY-MM-DD')}<br />
                        {dayjs(job.createdAt).format('HH:mm:ss')}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

        </Row>
        <Row className="bg-white pb-4">
          <Col>
            <Pagination
              activePage={page}
              itemsCountPerPage={20}
              totalItemsCount={resultCount}
              pageRangeDisplayed={10}
              onChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdminsJobsList