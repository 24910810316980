import { Container } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '@/api'

const Menus = ({ userName, navigation }: { userName: string, navigation: any }): JSX.Element => {
  if (sessionStorage.getItem("userType") === "admin") {
    return (
      <>
        <Navbar bg="white" className="navbar-light" expand="lg">
          <Container className="" fluid>
            <Navbar.Brand href="/">공드림 오피스(관리자)</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Navbar.Collapse>
                <Nav>
                  <Nav.Link href="/admins/users/agencies/list">가맹점관리</Nav.Link>
                  <Nav.Link href="/admins/users/customers/list">고객관리</Nav.Link>
                  <Nav.Link href="/admins/branches/codes/list">코드관리</Nav.Link>
                  <Nav.Link href="/admins/hosts/printers/list">프린터관리</Nav.Link>
                  <NavDropdown title="작업(결제)내역" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/admins/jobs/list-today">당일작업내역</NavDropdown.Item>
                    <NavDropdown.Item href="/admins/jobs/list">작업내역</NavDropdown.Item>
                    <NavDropdown.Item href="/admins/payments/logs">결제내역</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/admins/coupons/coupons/list">쿠폰내역</Nav.Link>
                  <Nav.Link href="/admins/payments/logs/by-agency">매출내역</Nav.Link>
                  <Nav.Link href="/admins/images/list">광고관리</Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Nav>
                <NavDropdown title={userName + "님"} id="basic-nav-dropdown">
                  <NavDropdown.Item
                    onClick={() => { alert('준비중') }}
                  >
                    관리자정보수정
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  onClick={() => {
                    console.info(`Clicked!`)
                    api.config.emptySessionStorage();
                    navigation('/login')
                  }}
                >
                  로그아웃
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    )
  } else {
    return (
      <>
        <Navbar bg="dark" className="navbar-light" expand="lg" variant='dark'>
          <Container className="" fluid>
            <Navbar.Brand href="/">공드림 오피스(가맹점)</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Navbar.Collapse>
                <Nav>
                  <Nav.Link href="/agencies/branches/codes/list">코드관리</Nav.Link>
                  <Nav.Link href="/agencies/hosts/printers/list">프린터관리</Nav.Link>
                  <Nav.Link href="/agencies/payments/logs">결제내역</Nav.Link>
                  {/* <Nav.Link href="/admins/coupons/coupons/list">쿠폰내역</Nav.Link> */}
                  <Nav.Link href="/agencies/payments/logs/by-agency">매출내역</Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Nav>
                <NavDropdown title={userName + "님"} id="basic-nav-dropdown">
                  <NavDropdown.Item
                    onClick={() => { alert('준비중') }}
                  >
                    비밀번호수정
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  onClick={() => {
                    console.info(`Clicked!`)
                    api.config.emptySessionStorage();
                    navigation('/login')
                  }}
                >
                  로그아웃
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}

const Navigation = () => {

  const [userName, setUserName] = useState('')

  useEffect(() => {

    if (sessionStorage.getItem("userType") === "admin") {
      api.admins.users.admins.me().then((result: any) => {
        if (result.result.admin.userName) {
          setUserName(result.result.admin.userName)
        }
      }).catch((e) => {
        console.error(e)
        alert("서버오류가 발생하였습니다")
      })
    } else {
      api.agencies.users.agencies.me().then((result: any) => {
        if (result.result.agency.userName) {
          setUserName(result.result.agency.userName)
        }
      }).catch((e) => {
        console.error(e)
        alert("서버오류가 발생하였습니다")
      })
    }
  }, [])


  const navigation = useNavigate();



  return (
    <>
      <Menus
        userName={userName}
        navigation={navigation}
      />
    </>
  );
}

export default Navigation;
