import axios, { AxiosRequestConfig } from 'axios'
import { API_BASE_URL } from '@/consts/api'
const axiosConfig: AxiosRequestConfig = {
  baseURL: API_BASE_URL
}
const axiosInstance = axios.create(axiosConfig)

try {
  const token = sessionStorage.getItem("token")
  axios.defaults.headers.common['Authorization'] = `${token}`
  axiosInstance.defaults.headers.common['Authorization'] = `${token}`
  axiosInstance.interceptors.response.use((response: any) => {
    console.info(`[API] ${response.config.method} ${response.config.url} ${response.status} ${response.statusText}`)
    return response;
  }, (error: any) => {
    console.error(`[API] ${error.config.method} ${error.config.url} ${error.response.status} ${error.response.statusText}`)
    window.location.href = "/login"
    return Promise.reject(error)
  })
} catch (e) {
  console.error(e)
}

const backofficeApi = {
  images: {
    listImages: (params: any) => new Promise((resolve, reject) =>
      axiosInstance({
        method: 'get',
        url: `/web-backoffice/images?per_page=10&page=${params.page}&query=${params.query}`,
      }).then((res) => resolve(res.data)).catch((e) => reject(e))
    ),
    createImage: (params: any) => new Promise((resolve, reject) =>
      axiosInstance({
        method: 'post',
        url: `/web-backoffice/images`,
        data: params
      }).then((res) => resolve(res.data)).catch((e) => reject(e))
    ),
    getUploadURL: (params: any) => new Promise((resolve, reject) =>
      axiosInstance({
        method: 'get',
        url: `/web-backoffice/images/upload_url`,
      }).then((res) => resolve(res.data)).catch((e) => reject(e))
    ),
  },
  jobs: {
    listJobs: (params: any) => new Promise((resolve, reject) =>
      axiosInstance({
        method: 'get',
        url: `/web-backoffice/jobs?per_page=20&page=${params.page}`,
      }).then((res) => resolve(res.data)).catch((e) => reject(e))
    ),
    countAllJobs: () => new Promise((resolve, reject) =>
      axiosInstance({
        method: 'get',
        url: `/web-backoffice/jobs/count-all`,
      }).then((res) => resolve(res.data)).catch((e) => reject(e))
    ),
    listJobsToday: (params: any) => new Promise((resolve, reject) =>
      axiosInstance({
        method: 'get',
        url: `/web-backoffice/jobs/today?per_page=20&page=${params.page}`,
      }).then((res) => resolve(res.data)).catch((e) => reject(e))
    ),
    countAllJobsToday: () => new Promise((resolve, reject) =>
      axiosInstance({
        method: 'get',
        url: `/web-backoffice/jobs/count-all-today`,
      }).then((res) => resolve(res.data)).catch((e) => reject(e))
    ),

  },
  coupons: {
    coupons: {
      listCoupons: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/coupons/coupons?per_page=10&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllCoupons: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/coupons/coupons/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      createCoupon: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'post',
          url: `/web-backoffice/coupons/coupons`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),

    }
  },
  payments: {
    logs: {
      listLogs: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/payments/logs?per_page=10&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllLogs: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/payments/logs/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getLogsByAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/payments/logs/by-agency-id?agencyId=${params.agencyId}&dateStart=${params.dateStart}&dateEnd=${params.dateEnd}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),

    }
  },
  branches: {
    codes: {
      listCodes: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/branches/codes?per_page=10&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllCodes: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/branches/codes/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      createCode: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'post',
          url: `/web-backoffice/branches/codes`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getCode: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/branches/codes/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      patchCode: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-backoffice/branches/codes/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      deleteCode: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'delete',
          url: `/web-backoffice/branches/codes/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
    }

  },
  hosts: {
    printers: {
      createPrinter: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'post',
          url: `/web-backoffice/hosts/printers`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      listPrinters: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/hosts/printers?per_page=10&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      listPrintersByAgencyId: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/hosts/printers/by-agency-id?query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllPrinters: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/hosts/printers/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getPrinter: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/hosts/printers/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      patchPrinter: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-backoffice/hosts/printers/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),

    }
  },
  users: {
    admins: {
      me: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/admins/me`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
    },
    customers: {
      countAllCustomers: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/users/customers/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      listCustomers: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/customers?per_page=${params.per_page || "10"}&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getCustomer: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/customers/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
    },
    agencies: {
      listAgencies: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/agencies?per_page=${params.perPage ? params.perPage : 10}&page=${params.page}&query=${params.query}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      countAllAgencies: () => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: '/web-backoffice/users/agencies/count-all',
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      getAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'get',
          url: `/web-backoffice/users/agencies/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      createAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'post',
          url: `/web-backoffice/users/agencies`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      patchAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-backoffice/users/agencies/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      deleteAgency: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'delete',
          url: `/web-backoffice/users/agencies/${params._id}`,
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
      changePassword: (params: any) => new Promise((resolve, reject) =>
        axiosInstance({
          method: 'patch',
          url: `/web-backoffice/users/agencies/password/${params._id}`,
          data: params
        }).then((res) => resolve(res.data)).catch((e) => reject(e))
      ),
    }
  }
}

export default backofficeApi